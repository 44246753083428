import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ArgonService } from 'src/app/services/argon.service';
import { AuthService } from 'src/app/services/auth.service';
import { promisify } from 'util';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export let ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: '' },
  { path: '/orders', title: 'Orders', icon: 'ni-bullet-list-67 text-red', class: '' }
  // { path: '/tables', title: 'Tables', icon: 'ni-bullet-list-67 text-red', class: '' },
  // { path: '/login', title: 'Login', icon: 'ni-key-25 text-info', class: '' },
  // { path: '/register', title: 'Register', icon: 'ni-circle-08 text-pink', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  public img;
  public menuItems: any[];
  public isCollapsed = true;
  public loadMenu = false;
  public menuPushed = false;
  role = "";
  business = '';
  constructor(private router: Router, private argonService: ArgonService, private authService: AuthService) { }

  ngOnInit() {
    this.argonService.getBusinessName().subscribe(bName => {
      this.role = bName.data.role;
      if (!this.menuPushed && bName.data.role === 'admin' || bName.data.role === 'superAdmin') {
        console.log(this.menuPushed);
        this.menuPushed = true;
        ROUTES.push({ path: '/accounts', title: 'Accounts', icon: 'ni-pin-3 text-orange text-red', class: '' },
          { path: '/categories', title: 'Categories', icon: '', class: '' },
          { path: '/transactions', title: 'Transactions'/*, icon: 'ni-single-02 text-yellow',*/, icon: '', class: '' },
          { path: '/vendors', title: 'Vendors'/*, icon: 'ni-single-02 text-yellow',*/, icon: '', class: '' },
          { path: '/liability', title: 'Liability'/*, icon: 'ni-single-02 text-yellow',*/, icon: '', class: '' });
      }
      this.menuItems = ROUTES.filter(menuItem => menuItem);
      this.business = bName.data.business;
      if (this.business === 'penhouse') {
        this.img = 'assets/img/brand/Pen-House-Logo-New.png';
      } else if (this.business === 'shoplik') {
        this.img = 'assets/img/brand/logo_Final.png';
      } else if (this.business === 'customhouse') {
        this.img = 'assets/img/brand/Custom-House-Logo.png';
      }
    });
    this.business = this.argonService.getBusiness();
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
  }

  logout() {
    this.authService.logout();
  }

  public trackMenuItem(index: number, menuRoute: any) {
    return menuRoute.path;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.

    // set the default routes on destruction of component
    ROUTES = [  { path: '/dashboard', title: 'Dashboard', icon: 'ni-tv-2 text-primary', class: '' },
    { path: '/orders', title: 'Orders', icon: 'ni-bullet-list-67 text-red', class: '' }];
    //Add 'implements OnDestroy' to the class.

  }
}
