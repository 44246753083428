import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { authModel } from './authModel';
import { ArgonService } from './argon.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {


  constructor(private http: HttpClient, private router: Router, private argonService: ArgonService) { }
  authToken: string;
  authListner = new Subject<boolean>();
  isAuthenticated = false;
  timer: any;
  userId: string;
  appUrl = environment.appUrl;

  getAuthListner() {
    return this.authListner.asObservable();
  }

  getAuthStatus() {
    return this.isAuthenticated;
  }
  getToken() {
    return this.authToken;
  }

  loginLogic(email: string, password: string, business: string) {
    const authData: authModel = {
      email, password, business
    };
    this.http.post<{ token: string, expiresIn: number, userId: string, role: string }>(this.appUrl + '/api/user/login', authData)
      .subscribe(response => {
        this.authToken = response.token;
        if (this.authToken) {
          this.isAuthenticated = true;
          this.authListner.next(this.isAuthenticated);
          const expiresIn = response.expiresIn;
          this.userId = response.userId;
          this.saveAuthData(this.authToken, expiresIn, response.userId, response.role);
          this.timerExpiration(expiresIn);  // takes the arguemnts in seconds and convert them in miliseconds
          // save the items in local storage for future use
          this.router.navigate(['/']);
        }
      });
  }

  logout() {
    this.authToken = null;
    this.isAuthenticated = false;
    this.authListner.next(this.isAuthenticated);
    this.userId = null;
    clearTimeout(this.timer);
    this.clearAuthData();
    this.router.navigate(['/login']);
    this.argonService.emptyOrders();
  }


  autoAuthenticate() {
    const authData = this.getAuthData();
    const localToken = authData.token;
    const localDuration = authData.expiresIn;
    if (!localToken || !localDuration) {
      return;
    }

    const now = new Date();
    const expirationDate = new Date(localStorage.getItem('expiresIn'));
    const expiration = expirationDate.getTime() - now.getTime();
    if (expiration > 0) {

      this.authToken = localToken;
      this.timerExpiration(expiration / 1000);
      this.isAuthenticated = true;
      this.authListner.next(this.isAuthenticated);
    }
  }


  private timerExpiration(duration: number) {
    this.timer = setTimeout(() => {
      this.logout();
    }, duration * 1000); // expects the value in miliseconds
  }

  private saveAuthData(token: string, expiration: number, userId: string, role: string) {
    localStorage.setItem('token', token);
    const now = new Date();
    // stores the time of expiration from the current time in the local storage
    localStorage.setItem('expiresIn', new Date(now.getTime() + expiration * 1000).toISOString());
    localStorage.setItem('userId', userId);
    localStorage.setItem('role', role);
  }

  private clearAuthData() {
    localStorage.removeItem('token');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('userId');
    localStorage.removeItem('role');
  }

  private getAuthData() {
    const authData = {
      token: localStorage.getItem('token'),
      expiresIn: localStorage.getItem('expiresIn'),
      userId: localStorage.getItem('userId'),
      role: localStorage.getItem('role'),
     };
    return { ...authData };
  }

  public getUserId() {
    return this.userId; // localStorage.getItem('userId');
  }

}
