<h1 mat-dialog-title>
  Create Transaction
</h1>
<mat-dialog-content>

  <!--//////////// Transaction Addition Modal ///////////-->

  <ng-container *ngIf="modalType === 'add-item'">

    <form (submit)=addItem(data) #data="ngForm" align="center" style="width: 400px;">

      <mat-form-field appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="text" name="name" required ngModel #input="ngModel">
        <mat-error *ngIf="input.invalid">Please Enter valid a Name</mat-error>
      </mat-form-field><br>


      <mat-form-field appearance="outline">
        <mat-label>Quantity</mat-label>
        <input matInput type="number" name="quantity" ngModel #quantity min="0" >
      </mat-form-field><br><br>

      <br><br>
      <button mat-raised-button color="primary" type="submit">Save</button>
    </form>

  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Okay</button>
</mat-dialog-actions>
