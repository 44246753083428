<h1 mat-dialog-title>
  {{title | titlecase}}
</h1>

<mat-dialog-content>
  <ng-container *ngIf="type == 'bookOrders'">

    <div class="table-responsive table-light table-scroll" *ngIf="!isLoading">
      <table mat-table [dataSource]="orderSource" class="mat-elevation-z8">


        <ng-container matColumnDef="sr_no" sticky>
          <th mat-header-cell *matHeaderCellDef> SR_No  </th>
          <td mat-cell *matCellDef="let element; let i = index;"> {{i+1}} </td>
        </ng-container>


        <ng-container matColumnDef="cn" sticky>
          <th mat-header-cell *matHeaderCellDef> CN</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center">
              <span class="mr-2 blue"><a (click)="openOrderDetailsDialog(element)"> {{element.cn}}</a></span>
            </div>
          </td>
        </ng-container>


        <ng-container matColumnDef="orderId" sticky>
          <th mat-header-cell *matHeaderCellDef> Order Id</th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center">
              <span class="mr-2"> {{element.orderId}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef> Name </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center">
              <span class="mr-2"> {{element.billing.first_name}} {{element.billing.last_name}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="phone">
          <th mat-header-cell *matHeaderCellDef> Phone </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center">
              <span class="mr-2"> {{element.billing.phone}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="address">
          <th mat-header-cell *matHeaderCellDef [ngClass]="'matTablewidth100px'"> Address </th>
          <td mat-cell [ngClass]="'matTablewidth100px'" *matCellDef="let element">
            <div class="d-flex align-items-center">
              <span class="mr-2 uk-form-width-large"> {{element.billing.address}}, {{element.billing.city}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="city">
          <th mat-header-cell *matHeaderCellDef> City </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center">
              <span class="mr-2" *ngIf="!enableCities">
                {{element.billing.city}}
              </span>
              <span *ngIf="hasProperty(element.billing,'cityCompanydetails')" class="green">
                <button mat-button color="accent" (click)="enableCity(element)"> <i class="fa fa-check"
                    aria-hidden="true"></i> {{element.billing.cityCompanydetails.company}} </button>
              </span>
              <span *ngIf="!hasProperty(element.billing,'cityCompanydetails')">
                <button mat-button color="accent" (click)="enableCity(element)"> <i class="fa fa-times"
                    aria-hidden="true"></i> </button>
              </span>
              <span *ngIf="enableCities">

                <mat-form-field mat-stroked-button class="align-content-center">
                  <mat-select (valueChange)="updateCity(citySelection.value, element)" [(value)]="element.status"
                    #citySelection>
                    <mat-option *ngFor="let city of cities" [value]="city">
                      {{city.name | titlecase}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="notes">
          <th mat-header-cell *matHeaderCellDef> Customer Notes </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center">
              <span class="mr-2"> {{element.notes}}</span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef> Status </th>
          <td mat-cell *matCellDef="let element">
            <div class="d-flex align-items-center">
              <span [ngClass]="{
                'red':element.status == 'return' || element.status == 'cancel',
                'green': element.status == 'confirm',
                'sky-blue':element.status == 'pending',
                'purple':element.status == 'printing',
                'yellow':element.status == 'call again',
                'orange':element.status =='dispatch',
                'brown':element.status == 'advance pending'
              }">
                <i class="fa fa-circle" aria-hidden="true"></i>
              </span> {{element.status | titlecase}}
            </div>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="orderColumns,sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: orderColumns;"></tr>
      </table>
    </div>
  </ng-container>


  <ng-container *ngIf="type == 'changeCity'">
    <mat-spinner *ngIf="citiesLoading"></mat-spinner>
    <ng-container *ngIf="!citiesLoading">
      <mat-form-field mat-stroked-button class="align-content-center">
        <mat-select (valueChange)="updateCity(citySelection.value)" #citySelection>
          <mat-option *ngFor="let city of cities" [value]="city">
            {{city.name | titlecase}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="type == 'viewOrder'">
    <form (submit)="onSave(orderForm)" #orderForm="ngForm">
      <span *ngIf="type == 'viewOrder'" class="float-right">
        <button type="submit" mat-raised-button color="primary">
          {{buttonTitle}}
        </button>
      </span>
      <div style="border: 2px black solid; border-radius: 15px;">
        <h1 class="text-center">Customer Information</h1>
        <div class="col-md-4 col-sm-12 d d-inline-block">
          <p *ngIf="!editMode"><strong>First Name: </strong>{{singleOrder.billing.first_name}}</p>
          <mat-form-field *ngIf="editMode">
            <mat-label>First Name</mat-label>
            <input name="first_name" #first_name="ngModel" matInput placeholder="Enter the first name"
              [ngModel]="singleOrder.billing.first_name">
          </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 d-inline-block">
          <p *ngIf="!editMode"><strong>Last Name: </strong> {{singleOrder.billing.last_name}} </p>
          <mat-form-field *ngIf="editMode">
            <mat-label>Last Name</mat-label>
            <input name="last_name" #last_name matInput placeholder="Enter the first name"
              [ngModel]="singleOrder.billing.last_name">
          </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 d-inline-block">
          <h2 *ngIf="!editMode"><strong>Amount: </strong>Rs {{singleOrder.total}}</h2>
          <mat-form-field *ngIf="editMode">
            <mat-label>Amount</mat-label>
            <input type="number" name="total" #total matInput placeholder="Enter the first name"
              [ngModel]="singleOrder.total">
          </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 d-inline-block">
          <p *ngIf="!editMode"><strong>Phone No: </strong>{{singleOrder.billing.phone}}</p>
          <mat-form-field *ngIf="editMode">
            <mat-label>Phone No</mat-label>
            <input name="phone" #phone matInput placeholder="Enter the first name"
              [ngModel]="singleOrder.billing.phone">
          </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 d-inline-block">
          <p *ngIf="!editMode"><strong>Email: </strong>{{singleOrder.billing.email}}</p>
          <mat-form-field *ngIf="editMode">
            <mat-label>Email</mat-label>
            <input name="email" #email matInput placeholder="Enter the first name"
              [ngModel]="singleOrder.billing.email">
          </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 d-inline-block">
          <p><strong>Last Updated By: </strong>{{singleOrder.lastUpdatedBy.name}}</p>
        </div>
        <div class="col-md-8 col-sm-12 d-inline-block">
          <p *ngIf="!editMode"><strong>Address: </strong>{{singleOrder.billing.address}}</p>
          <mat-form-field *ngIf="editMode" class="w-100">
            <mat-label>Address</mat-label>
            <input name="address" matInput #address placeholder="Enter the first name"
              [ngModel]="singleOrder.billing.address">
          </mat-form-field>
        </div>
        <div class="col-md-4 col-sm-12 d-inline-block">
          <p *ngIf="!editMode"><strong>City: </strong>{{singleOrder.billing.city}}</p>
          <mat-form-field *ngIf="editMode">
            <mat-label>City</mat-label>
            <input name="city" #city matInput placeholder="Enter the first name" [ngModel]="singleOrder.billing.city">
          </mat-form-field>
        </div>
        <div class="col-md-6 col-sm-12 d-inline-block" *ngIf="singleOrder.notes">
          <p><strong>Customer Notes: </strong>{{singleOrder.notes}}</p>
        </div>
        <div class="col-md-6 col-sm-12 d-inline-block" *ngIf="singleOrder.remarks || editMode">
          <p *ngIf="!editMode"><strong>Remarks: </strong>{{singleOrder.remarks}}</p>
          <mat-form-field *ngIf="editMode" class="w-100">
            <mat-label>Remarks</mat-label>
            <input name="remarks" #remarks matInput placeholder="Enter the first name" [ngModel]="singleOrder.remarks">
          </mat-form-field>
        </div>
        <div [ngClass]="{
          'red':singleOrder.status == 'return' || singleOrder.status == 'cancel',
          'green': singleOrder.status == 'confirm' || singleOrder.status =='advance done',
          'sky-blue':singleOrder.status == 'pending',
          'purple':singleOrder.status == 'printing',
          'yellow':singleOrder.status == 'call again',
          'orange':singleOrder.status =='dispatch',
          'brown':singleOrder.status == 'advance pending'
        }" class="col-md-12 col-sm-12 d-inline-block">
          <h3 class="text-center">{{singleOrder.status | titlecase}}</h3>
        </div>


      </div>
    </form>


    <h1 class="text-center">Order Details</h1>
    <button mat-raised-button color="success" (click)="productAction('Add','')">
      Add
    </button>

    <table mat-table [dataSource]="singleOrder.products" class="mat-elevation-z8 table-margins">
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let product">
          {{product.name}}
        </td>
      </ng-container>

      <ng-container matColumnDef="price">
        <th mat-header-cell *matHeaderCellDef> Price </th>
        <td mat-cell *matCellDef="let product"> {{product.price}} </td>
      </ng-container>

      <ng-container matColumnDef="qty">
        <th mat-header-cell *matHeaderCellDef> Quantity </th>
        <td mat-cell *matCellDef="let product"> {{product.qty}} </td>
      </ng-container>

      <ng-container matColumnDef="nameToPrint">
        <th mat-header-cell *matHeaderCellDef> Name To Print </th>
        <td mat-cell *matCellDef="let product"> {{product.nameToPrint}} </td>
      </ng-container>

      <ng-container matColumnDef="nameOnOtherSide">
        <th mat-header-cell *matHeaderCellDef> Name On Other Side </th>
        <td mat-cell *matCellDef="let product"> {{product.nameOnOtherSide}} </td>
      </ng-container>

      <ng-container matColumnDef="giftWrap">
        <th mat-header-cell *matHeaderCellDef> Gift Wrap </th>
        <td mat-cell *matCellDef="let product"> {{product.giftWrap}} </td>
      </ng-container>

      <ng-container matColumnDef="size">
        <th mat-header-cell *matHeaderCellDef> Size </th>
        <td mat-cell *matCellDef="let product"> {{product.size}} </td>
      </ng-container>

      <ng-container matColumnDef="color">
        <th mat-header-cell *matHeaderCellDef> Color </th>
        <td mat-cell *matCellDef="let product"> {{product.color}} </td>
      </ng-container>

      <ng-container matColumnDef="mobileModel">
        <th mat-header-cell *matHeaderCellDef> Model </th>
        <td mat-cell *matCellDef="let product"> {{product.mobileModel}} </td>
      </ng-container>

      <ng-container matColumnDef="printingSide">
        <th mat-header-cell *matHeaderCellDef> Printing Side </th>
        <td mat-cell *matCellDef="let product"> {{product.printingSide}} </td>
      </ng-container>

      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef> Language </th>
        <td mat-cell *matCellDef="let product"> {{product.language}} </td>
      </ng-container>

      <ng-container matColumnDef="picture">
        <th mat-header-cell *matHeaderCellDef> Picture </th>
        <td mat-cell *matCellDef="let product"> {{product.picture}} </td>
      </ng-container>

      <ng-container matColumnDef="refills">
        <th mat-header-cell *matHeaderCellDef> Refills </th>
        <td mat-cell *matCellDef="let product"> {{product.refills}} </td>
      </ng-container>

      <!-- Action Column -->
      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let product" class="action-link">
          <a mat-raised-button (click)="productAction('Update',product)" color="primary">Edit</a> |
          <a mat-raised-button (click)="productAction('Delete',product)" color="accent">Delete</a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="productColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: productColumns;"></tr>
    </table>
    <br><br>
    <div *ngIf="singleOrder.dispatchDetails" class="uk-panel-divider"
      style="border: 2px black dashed; border-radius: 15px;">
      <h1 class="text-center">Dispatch Information</h1>
      <div *ngFor="let detail of singleOrder.dispatchDetails">
        <div class="col-md-6 col-sm-12 d-inline-block">
          <p><strong>Tracking ID: </strong><a
              (click)="trackOrder(detail.trackingId, detail.company)">{{detail.trackingId}}</a>
          </p>
        </div>
        <div class="col-md-6 col-sm-12 d-inline-block">
          <p><strong>Dispatch Date: </strong>{{detail.dispatchDate | date}}</p>
        </div>
        <div class="col-md-6 col-sm-12 d-inline-block">
          <p><strong>Company: </strong>{{detail.company | titlecase}}</p>
        </div>
        <div class="col-md-6 col-sm-12 d-inline-block">
          <p><strong>Dispatched By: </strong>{{detail.dispatchBy}}</p>
        </div>

      </div>
    </div>

    <h1 class="text-center">History</h1>
    <div class="col-md-12 col-sm-12 col-lg-12 d-inline-block">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            Change History
          </mat-panel-title>

          <mat-panel-description>
            Click here to check the change history of this order
          </mat-panel-description>
        </mat-expansion-panel-header>

        <table mat-table [dataSource]="singleOrder.orderLog" class="mat-elevation-z8">

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef> Action </th>
            <td mat-cell *matCellDef="let element"> {{element.action}} </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> Name </th>
            <td mat-cell *matCellDef="let element"> {{element.updatedBy.name}} </td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> Date </th>
            <td mat-cell *matCellDef="let element"> {{element.dateCreated | date}} </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="singleOrderViewColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: singleOrderViewColumns;"></tr>
        </table>
      </mat-expansion-panel>
    </div>

  </ng-container>

  <ng-container *ngIf="type == 'tracking'">
    <ng-container *ngIf="data.company == 'trax'">
      <mat-spinner *ngIf="isLoading" class="align-self-center"></mat-spinner>
      <div *ngIf="!isLoading" style="border: 2px black solid; border-radius: 15px; width: 100%;">
        <div *ngFor="let singleHistory of trackingHistory">
          <div class="col-md-12">
            <div class="col-md-4 d-inline-block"><strong>Status: </strong>{{singleHistory.status}}</div>
            <div class="col-md-4 d-inline-block"><strong>Date: </strong>{{singleHistory.date_time}}</div>
            <div class="col-md-4 d-inline-block"><strong>Reason: </strong>{{singleHistory.status_reason}}</div>
          </div>
          <hr>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="data.company == 'leopard'">
      <mat-spinner *ngIf="isLoading" class="align-self-center"></mat-spinner>
      <div *ngIf="!isLoading" style="border: 2px black solid; border-radius: 15px; width: 100%;">
        <div *ngFor="let singleHistory of trackingHistory">
          <div class="col-md-12">
            <div class="col-md-6 d-inline-block"><strong>Status: </strong>{{singleHistory.Status}}</div>
            <div class="col-md-6 d-inline-block"><strong>Date & Time: </strong>{{singleHistory.Activity_datetime}}</div>
            <!-- <div class="col-md-4 d-inline-block"><strong>Reason: </strong>{{singleHistory.status_reason}}</div> -->
          </div>
          <hr>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="type == 'createTrackingSheet'">
    <h1>
      Successfully Booked!
    </h1>
    <p>
      Create Receiving Sheet for these ids
    </p>
    <p *ngFor="let val of data.trackingIds">{{val}}</p>
  </ng-container>

  <ng-container *ngIf="type=='editProduct' || type=='addProduct'">
    <form (submit)="productSave(productForm)" #productForm="ngForm">
      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input name="name" #name="ngModel" matInput placeholder="Name"
            [ngModel]="type !== 'addProduct'?oneProduct.name:''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Price</mat-label>
          <input name="price" #price="ngModel" matInput placeholder="Price"
            [ngModel]="type !== 'addProduct'?oneProduct.price:''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Quantity</mat-label>
          <input name="qty" #qty="ngModel" matInput placeholder="Quantity"
            [ngModel]="type !== 'addProduct'?oneProduct.qty:''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Size</mat-label>
          <input name="size" #size="ngModel" matInput placeholder="Size"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'size')?oneProduct.size:'':''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Name to Be Printed</mat-label>
          <input name="nameToPrint" #nameToPrint="ngModel" matInput placeholder="Name To Be Printed"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'nameToPrint')?oneProduct.nameToPrint:'':''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Name on other side</mat-label>
          <input name="nameOnOtherSide" #nameOnOtherSide="ngModel" matInput placeholder="Name to print on other side"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'nameOnOtherSide')?oneProduct.nameOnOtherSide:'':''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Gift Wrap Options</mat-label>
          <input name="giftWrap" #giftWrap="ngModel" matInput placeholder="Gift Wrap Options"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'giftWrap')?oneProduct.giftWrap:'':''">
        </mat-form-field>
      </div>


      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Refill Options</mat-label>
          <input name="refills" #refills="ngModel" matInput placeholder="Add refill option"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'refills')?oneProduct.refills:'':''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Color</mat-label>
          <input name="color" #color="ngModel" matInput placeholder="Please enter the color"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'color')?oneProduct.color:'':''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Model</mat-label>
          <input name="mobileModel" #mobileModel="ngModel" matInput placeholder="Enter the model"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'mobileModel')?oneProduct.mobileModel:'':''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Printing Side</mat-label>
          <input name="printingSide" #printingSide="ngModel" matInput placeholder="Printing Side"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'printingSide')?oneProduct.printingSide:'':''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Language</mat-label>
          <input name="language" #language="ngModel" matInput placeholder="Add language options"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'language')?oneProduct.language:'':''">
        </mat-form-field>
      </div>

      <div class="col-md-4 col-sm-12 d d-inline-block">
        <mat-form-field>
          <mat-label>Picture</mat-label>
          <input name="picture" #picture="ngModel" matInput placeholder="picture Name to be uploaded"
            [ngModel]="type !== 'addProduct'?hasProperty(oneProduct,'picture')?oneProduct.picture:'':''">
        </mat-form-field>
      </div>


      <div class="col-md-12 d d-inline-block">
        <button mat-raised-button type="submit" color="accent">Save</button>
      </div>
    </form>
  </ng-container>


</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Okay</button>
</mat-dialog-actions>
