import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { ComponentsModule } from './components/components.module';
import { MaterialModule } from './material.module';
import { AuthInterceptor } from './services/auth-interceptor';
import { HttpDialogeComponent } from './services/auth-interceptor';

import { OrderModalComponent } from './pages/order/order-modal/order-modal.component';
import { AuthGuard } from './services/auth-guard';
import { AccountsModalComponent } from './pages/accounts/accounts-modal/accounts-modal.component';
import { InventoryModalComponent } from './pages/inventory/inventory-modal/inventory-modal.component';


@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    MaterialModule
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    OrderModalComponent,
    AccountsModalComponent,
    InventoryModalComponent,
    HttpDialogeComponent
  ],
  entryComponents: [OrderModalComponent, AccountsModalComponent, InventoryModalComponent, HttpDialogeComponent],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }, AuthGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
