import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { orderElement } from '../pages/order/order.model';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ArgonService {
  business = '';
  orders: orderElement[];
  appUrl = environment.appUrl;
  orderSubject = new Subject<orderElement[]>();
  singleOrderSubject = new Subject<orderElement>();
  productSubject = new Subject<{ orderId: string, products: any, orderLog: any }>();
  stats: any;
  statsListner = new Subject();
  cities: any;


  traxCitySubject = new Subject();

  leopardCities: any;
  leopardCitySubject = new Subject();

  constructor(private http: HttpClient) { }

  fetchOrders() {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/orders').subscribe(result => {
      console.log(result.data);
      this.orders = result.data;
      this.orderSubject.next([...this.orders]);
    });
  }
  replaceOrder(order: orderElement) {
    const index = this.orders.findIndex(existingOrder => existingOrder._id === order._id);
    this.orders[index].lastUpdatedBy = order.lastUpdatedBy;
    this.orders[index].orderLog = order.orderLog;
    this.orders[index].dispatchDetails = order.dispatchDetails;
    this.orders[index].status = order.status;
    this.orders[index].billing.city = order.billing.city;
    this.orders[index].billing.cityCompanydetails = order.billing.cityCompanydetails;
    this.singleOrderSubject.next({ ...order }); // return the order that has been changed
    return true;
  }

  getOrders() {
    return this.orders;
  }

  setOrders(ordersList: orderElement[]) {
    this.orders = [...ordersList];
    this.orderSubject.next([...ordersList]);  // inform every component that the orders component has been changed
  }

  getOrderListener() {
    return this.orderSubject.asObservable();
  }

  deleteOrder() {

  }

  getSingleOrderListner() {
    return this.singleOrderSubject.asObservable();
  }
  updateStatus(value, orderId) {
    return this.http.put<{ message: string, updated: boolean }>(this.appUrl + '/api/argon/orders/status/' + orderId, {
      status: value,
      id: orderId
    });
  }

  getTraxCityListner() {
    return this.traxCitySubject.asObservable();
  }

  bookByTrax(selectionData: any) {
    return this.http.put<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/trax/book', selectionData);
  }

  bookByLahore(selectionData: any) {
    return this.http.patch<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/lahore/book', selectionData);
  }

  downloadFile(fileName: any) {
    return this.http.get(this.appUrl + '/api/argon/download-file', {
      params: {
        file: fileName
      },
      responseType: 'blob'
    });
  }

  generatePrintfile(ids) {
    return this.http.patch<{ message: String, data: any }>(this.appUrl + '/api/argon/orders/generate-print-file', ids);
  }


  createTraxReceivingSheet(trackingIds: any) {
    return this.http.post<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/trax/create-receiving-sheet', trackingIds);
  }

  viewTraxReceivingSheet(receivingSheetNo) {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/trax/view-receiving-sheet', {
      params: {
        receivingSheetNo
      }
    });
  }

  getTraxCities() {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/trax/cities').subscribe(cities => {
      this.cities = cities.data;
      this.traxCitySubject.next(true);
    });
  }

  bookByLeopard(selectionData: any) {
    return this.http.put<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/leopard/book', selectionData);
  }

  updateOrderCity(city: any, order: any, company) {
    const payload = {
      orderId: order._id,
      city: city.name,
      cityId: city.id,
      company
    };


    return this.http.put<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/city/' + order._id, payload);
  }



  updateOrder(order: orderElement, historyDescription) {
    return this.http.put<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/update?historyDescription=' +
      historyDescription, order);
  }

  fetchStats() {
    this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/fetch-stats').subscribe(response => {
      console.log(response);
      this.stats = response.data;
      this.statsListner.next(this.stats);
    });
  }

  getStatsLinster() {
    return this.statsListner.asObservable();
  }


  getStats() {
    return this.stats;
  }

  // return trax cities
  getCities() {
    return this.cities;
  }

  setCities(cities: any) {
    this.cities = cities;
  }

  traxTrackingHistory(trackingId: string) {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/trax/track?trackingid=' + trackingId);
  }


  // Leopard Methods
  getLeopardCities() {
    return this.leopardCities;
  }

  fetchLeopardCities() {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/leopard/cities').subscribe(cities => {
      this.leopardCities = cities.data;
      console.log(this.leopardCities);
      this.leopardCitySubject.next(true);
    });
  }
  getLeopardCityListner() {
    return this.leopardCitySubject.asObservable();
  }

  leopardTrackingHistory(trackingId: string) {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/leopard/track?tracking_number=' + trackingId);
  }

  fetchLatestOrders() {
    return this.http.post<{ message: 'Fetched Latest Orders Successfully', data: any }>(this.appUrl + '/api/argon/orders/fetch', {});
  }

  getBusiness() {
    return this.business;
  }

  setBusiness(business: string) {
    this.business = business;
  }


  productListner() {
    return this.productSubject.asObservable();
  }


  updateOrderProducts(value) {
    const index = this.orders.findIndex(val => val._id === value.orderId);
    this.orders[index].products = value.products;
  }


  deleteProduct(orderId: string, productId: string) {
    this.http.delete<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/product', {
      params: {
        orderId,
        productId
      }
    }).subscribe(result => {

      // replace the local copy of service
      this.updateOrderProducts(result.data);
      // notify all about the deletion of the product
      this.productSubject.next(result.data);
    });
  }

  editProduct(values: object, product: any) {
    // console.log("herer");
    this.http.put<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/product', { values, product }).subscribe(result => {
      this.updateOrderProducts(result.data);
      this.productSubject.next(result.data);
    });
  }

  addProduct(orderId: string, product: any) {
    this.http.post<{ message: string, data: any }>(this.appUrl + '/api/argon/orders/product', { values: { orderId }, product })
      .subscribe(result => {
        this.updateOrderProducts(result.data);
        this.productSubject.next(result.data);
      });
  }

  emptyOrders() {
    this.orders = undefined;
    this.orderSubject.next(undefined);
  }

  getBusinessName() {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/get-business-name');
  }
}

// handleStatuses(res: Response, error) {
//   if (res) {
//     if (res.status === 201) {
//       return [{ status: res.status, json: res }]
//     }
//     else if (res.status === 200) {
//       return [{ status: res.status, json: res }]
//     }
//   }
//   if (error) {

//     if (error.status === 500) {
//       return Observable.throw(new Error(error.status));
//     } else if (error.status === 400) {
//       return Observable.throw(new Error(error.status));
//     } else if (error.status === 409) {
//       return Observable.throw(new Error(error.status));
//     } else if (error.status === 406) {
//       return Observable.throw(new Error(error.status));
//     }
//   }
// }
