import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  appUrl = environment.appUrl;
  inventorySubject = new Subject();


  constructor(private http: HttpClient) { }

  inventoryListener() {
    return this.inventorySubject.asObservable();
  }

  pushToInventoryListner(data) {
    this.inventorySubject.next(data);
  }

  fetchAllItems() {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/inventory/');
  }

  addItem(data) {
    return this.http.post<{ message: string, data: any }>(this.appUrl + '/api/argon/inventory/', data);
  }

  addVendorItem(data) {
    return this.http.post<{ message: string, data: any }>(this.appUrl + '/api/argon/inventory/add-vendor-item', data);
  }

  removeVendorItem(data){
    return this.http.post<{ message: string, data: any }>(this.appUrl + '/api/argon/inventory/remove-vendor-item', data);
  }


}
