import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AccountingService {

  appUrl = environment.appUrl;
  private accountName: string;
  transactionListner = new Subject();
  vendorListner = new Subject();
  liabilityListner = new Subject();
  accountListner = new Subject<{ _id: any }>();
  categoryListner = new Subject();

  constructor(private http: HttpClient) { }


  getAccountListner() {
    return this.accountListner.asObservable();
  }

  setAccountListner(value: any) {
    this.accountListner.next(value);
  }

  getVendorListner() {
    return this.vendorListner.asObservable();
  }

  setVendorListner(value: any) {
    this.vendorListner.next(value);
  }

  getCategoryListner() {
    return this.categoryListner.asObservable();
  }

  getLiabilityListner() {
    return this.liabilityListner.asObservable();
  }
  setCategoryListner(value: any) {
    this.categoryListner.next(value);
  }

  saveCategory(categoryName: string, type: string, desc: string) {
    const categoryData = { categoryName, type, desc };
    return this.http.post<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/category', categoryData);
  }


  getTransactionListner() {
    return this.transactionListner.asObservable();
  }

  getCategoryName(type: string) {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/category', { params: { type } });
  }

  saveMethod(name: string, balance: number, desc: string) {
    const accountData = { accountName: name, balance: balance, desc: desc };
    return this.http.post<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/account', accountData);
  }

  getAccounts() {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/account');
  }

  getVendors() {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/vendor');
  }

  createVendor(data: any) {
    return this.http.post<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/vendor', data);
  }

  getliability(queryParams: any) {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/liability', {
      params: queryParams
    });
  }

  createliability(data: any) {
    return this.http.post<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/liability', data)
      .subscribe(result => {
        this.liabilityListner.next(result.data);
      });
  }

  transactionData(data: any) {
    this.http.post<{ meesage: string, data: any }>(this.appUrl + '/api/argon/ledger/', data)
      .subscribe(result => {
        this.transactionListner.next(result.data);
      });
  }

  getTransactionData(queryParams: any) {
    return this.http.get<{
      transaction: any,
      message: string,
      maxTransaction: number
    }>(this.appUrl + '/api/argon/ledger/', {
      params: queryParams
    });

  }

  transferAccounts(data: any) {
    return this.http.patch<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/transfer-accounts', data);
  }

  getTransaction(id: string) {
    return this.http.get<{ message: string, data: any }>(this.appUrl + '/api/argon/ledger/' + id);
  }

  updateTransaction(id: string, balance: number, desc: string, type: string, account: string, category: string) {
    const updateData = { id: id, balance: balance, type: type, desc: desc, account: account, category: category };
    this.http.put(this.appUrl + '/api/argon/ledger/' + id, updateData)
      .subscribe(updatedTransaction => {
        console.log(updatedTransaction);
      })
  }

  getAcountTransaction(account: any, transactionPerPage: number, currentPage: number) {
    const queryParams = `?limit=${transactionPerPage}&page=${currentPage}&account=${account}`;
    return this.http.get<{ message: string, transaction: any, maxTransaction: number }>(this.appUrl + '/api/argon/ledger/' + queryParams);
  }
}
