import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AccountingService } from 'src/app/services/accounting.service';
import { NgForm } from '@angular/forms';
import { Router, ParamMap } from '@angular/router';

@Component({
  selector: 'app-accounts-modal',
  templateUrl: './accounts-modal.component.html',
  styleUrls: ['./accounts-modal.component.css']
})
export class AccountsModalComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<AccountsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog,
    private accountingService: AccountingService,
    private router: Router) { }


  private transactionid: string
  transferButton = false;
  modalType = '';
  mode = 'create';
  check = false;
  vendors;
  categories;
  accountName;
  fetchedData;
  liabilityAccounts = false;
  type = '';
  account = '';
  category = '';


  async onTransactionData(form: NgForm) {
    if (form.invalid) {
      return;
    }

    const data = {
      balance: form.value.balance,
      desc: form.value.desc,
      type: form.value.type,
      account: form.value.account,
      category: form.value.category
    }
    0
    if (this.mode === 'create') {
      await this.accountingService.transactionData(data);
      form.resetForm();
      this.dialogRef.close();
    } else {
      this.accountingService.updateTransaction(
        this.transactionid,
        form.value.balance,
        form.value.desc,
        form.value.type,
        form.value.account,
        form.value.category);
      form.resetForm();
    }

  }


  onSaveMethod(methodForm: NgForm) {
    this.accountingService.saveMethod(methodForm.value.name, methodForm.value.balance, methodForm.value.desc).subscribe(result => {
      methodForm.resetForm();
      this.dialogRef.close();
      this.accountingService.setAccountListner(result.data);
    });
  }

  getAccounts() {
    this.accountingService.getAccounts()
      .subscribe(result => {
        this.accountName = result.data;
      });
  }


  getData(val: any) {
    if (this.check === false) {
      this.getAccounts();
      this.check = true;
    }
    this.getCategories(val.value);
  }

  getCategories(type: any) {
    console.log(type);
    this.accountingService.getCategoryName(type)
      .subscribe(result => {
        this.categories = result.data;
        console.log(this.categories);
      });
  }

  onSavecategory(form: NgForm) {
    this.accountingService.saveCategory(form.value.name, form.value.type, form.value.desc).subscribe(result => {
      form.resetForm();
      this.dialogRef.close();
      this.accountingService.setCategoryListner(result.data);
    });
  }




  transferAccount(form: NgForm) {
    if (form.value.fromAccount === form.value.toAccount) {
      alert("cannot transfer withing same account");
      return;
    }
    this.transferButton = true;
    this.accountingService.transferAccounts(form.value).subscribe(result => {
      result.data.forEach(account => {
        this.accountingService.setAccountListner(account);
      });
      this.dialogRef.close();
    });
  }


  createVendor(form: NgForm) {
    this.accountingService.createVendor(form.value).subscribe(result => {
      this.accountingService.setVendorListner(result.data);
      this.dialogRef.close();
    });
  }


  createLiability(form: NgForm) {
    this.accountingService.createliability(form.value);
    form.resetForm();
    this.dialogRef.close();
  }


  setLiabilityType(event: any) {
    if (event.value === 'debit') {
      this.liabilityAccounts = true;
      this.getAccounts();
    } else {
      this.liabilityAccounts = false;
    }
  }

  ngOnInit() {
    this.modalType = this.data.type;

    if (this.modalType === 'transfer-account') {
      this.getAccounts();
    } else if (this.modalType === 'create-liability') {
      this.accountingService.getVendors().subscribe(result => {
        this.vendors = result.data;
        console.log(this.vendors);
      });
    }
    // this.route.paramMap.subscribe((paramMap: ParamMap) => {

    //   if (paramMap.has('transactionId')) {
    //     this.mode = 'edit';
    //     this.transactionid = paramMap.get('transactionId');
    //     this.accountingService.getTransaction(this.transactionid).subscribe(data => {
    //       this.fetchedData = data.data;
    //       console.log(this.fetchedData);
    //       console.log(this.fetchedData.categoryId.name)
    //       this.getCategories(this.fetchedData.type);
    //     });
    //   } else {
    //     this.mode = 'create',
    //       this.transactionid = null;
    //   }
    // });
  }

}
