<h1 mat-dialog-title>
  Create Transaction
</h1>
<mat-dialog-content>

  <!--//////////// Transaction Addition Modal ///////////-->

  <ng-container *ngIf="modalType === 'create-transaction'">


    <form (submit)=onTransactionData(data) #data="ngForm" align="center" style="width: 400px;">

      <mat-form-field appearance="outline">
        <mat-label>Add Amount</mat-label>
        <input matInput type="number" name="balance" required ngModel #input="ngModel" min="0" pattern="[0-9]*"
          [ngModel]="fetchedData?.amount">
        <span matPrefix>Rs &nbsp;</span>
        <span matSuffix>.00</span>
        <mat-error *ngIf="input.invalid">Please Enter valid a amount</mat-error>
      </mat-form-field><br>


      <mat-form-field hintLabel="Max 50 characters" appearance="outline">
        <mat-label>Add Description</mat-label>
        <textarea matInput type="text" name="desc" ngModel #desc maxlength="50"
          [ngModel]="fetchedData?.description"></textarea>
        <mat-hint align="end">{{desc.value?.length || 0}}/50</mat-hint>
      </mat-form-field><br><br>

      <label>
        <h2>Select type</h2>
      </label><br>
      <mat-radio-group name="type" (change)="getData($event)" [(ngModel)]="type" required #select="ngModel"
        [value]="fetchedData?.type" ng-readonly="all">
        <mat-radio-button value="income">Income</mat-radio-button>
        <mat-radio-button value="expense">Expense</mat-radio-button>
        <mat-error *ngIf="select.invalid" style="font-size: 12px;">Please select a type</mat-error>
      </mat-radio-group>

      <ng-container *ngIf="check || mode=='edit'">

        <mat-form-field>
          <mat-label>Select Account</mat-label>
          <mat-select name="account" [(ngModel)]="account" required #accountData="ngModel"
            [value]="fetchedData?.accountMethod.paymentMethod">
            <mat-option *ngFor="let account of accountName" [value]="account._id">{{account.paymentMethod}}</mat-option>
          </mat-select>
          <mat-error *ngIf="accountData.invalid">Please Select an account</mat-error>
        </mat-form-field><br>
        <mat-form-field>
          <mat-label>Select Category</mat-label>
          <mat-select name="category" [(ngModel)]="category" required #categoryData="ngModel">
            <mat-option *ngFor="let category of categories" [value]="category._id">{{category.name}}</mat-option>
            <mat-error *ngIf="categoryData.invalid">Please Select an category</mat-error>
          </mat-select>
        </mat-form-field>
        <br>

      </ng-container>


      <!-- <input type="file" name="image" /> -->
      <br><br>
      <button mat-raised-button color="primary" type="submit">Save</button>
    </form>

  </ng-container>


  <!--//////////// Accounts Addition Modal ///////////-->

  <ng-container *ngIf="modalType === 'create-account'">
    <form (submit)=onSaveMethod(methodForm) #methodForm="ngForm" class="form">
      <mat-form-field appearance="outline">
        <mat-label>Add Bank</mat-label>
        <input matInput type="text" name="name" ngModel placeholder="Add Bank" required #bankName="ngModel">
        <mat-error *ngIf="bankName.invalid">Please Enter a bank name</mat-error>
      </mat-form-field><br>

      <mat-form-field appearance="outline">
        <mat-label>Opening Balance</mat-label>
        <input matInput type="number" name="balance" required ngModel #balance="ngModel" min="0" pattern="[0-9]*">
        <mat-error *ngIf="balance.invalid">Please Enter a Balance</mat-error>
      </mat-form-field><br>

      <!-- <mat-form-field appearance="outline">
        <mat-label>Add Description</mat-label>
        <input matInput type="text" name="desc" ngModel>
    </mat-form-field><br> -->

      <mat-form-field hintLabel="Max 50 characters" appearance="outline">
        <mat-label>Add Description</mat-label>
        <textarea matInput type="text" name="desc" ngModel #desc maxlength="50"></textarea>
        <mat-hint align="end">{{desc.value?.length || 0}}/50</mat-hint>
      </mat-form-field><br><br>
      <button mat-raised-button color="primary" type="submit">Save Bank</button><br><br>
    </form>
  </ng-container>


  <!--//////////// Category Addition Modal /////////// -->

  <ng-container *ngIf="modalType === 'create-category'">
    <form (submit)=onSavecategory(category) #category="ngForm" class=" form">
      <mat-form-field appearance="outline">
        <mat-label>Add Category</mat-label>
        <input matInput type="text" name="name" required ngModel #name="ngModel">
        <mat-error *ngIf="name.invalid">Please Enter Category Name</mat-error>
      </mat-form-field><br>

      <mat-form-field appearance="outline">
        <mat-label>Add Description</mat-label>
        <textarea matInput type="text" name="desc" ngModel></textarea>
      </mat-form-field><br>

      <label>
        <h2>Type:</h2>
      </label><br>
      <mat-radio-group ngModel name="type" required>
        <mat-radio-button value="income">Income</mat-radio-button><br>
        <mat-radio-button value="expense">Expense</mat-radio-button><br>
        <mat-radio-button value="other">Other</mat-radio-button>
      </mat-radio-group><br><br>
      <button mat-raised-button color="primary" type="submit">Save Category</button><br><br>
    </form>
  </ng-container>

  <!--//////////// Transfer within accounts Modal /////////// -->

  <ng-container *ngIf="modalType === 'transfer-account'">
    <form (submit)=transferAccount(form) #form="ngForm" class="form">
      <mat-form-field>
        <mat-label>Transfer from</mat-label>
        <mat-select name="fromAccount" ngModel required #fromAccount="ngModel">
          <mat-option *ngFor="let account of accountName" [value]="account._id">{{account.paymentMethod}}</mat-option>
          <mat-error *ngIf="fromAccount.invalid">Please Select the account to transfer from</mat-error>
        </mat-select>
      </mat-form-field>
      <br>
      <mat-form-field>
        <mat-label>Transfer To</mat-label>
        <mat-select name="toAccount" ngModel required #toAccount="ngModel">
          <mat-option *ngFor="let account of accountName" [value]="account._id">{{account.paymentMethod}}</mat-option>
          <mat-error *ngIf="toAccount.invalid">Please Select the account to transfer to</mat-error>
        </mat-select>
      </mat-form-field>
      <br>
      <mat-form-field appearance="outline">
        <mat-label>Amount</mat-label>
        <input matInput type="number" name="amount" ngModel #amount>
        <mat-hint align="end">Enter amount to transfer</mat-hint>
      </mat-form-field><br>
      <button mat-raised-button ng-disabled="transferButton" color="primary" type="submit">Transfer</button><br>
    </form>
  </ng-container>


  <!--//////////// Create Vendor Modal /////////// -->

  <ng-container *ngIf="modalType === 'create-vendor'">
    <form (submit)=createVendor(form) #form="ngForm" class="form">
      <mat-form-field appearance="outline">
        <mat-label>Vendor Name</mat-label>
        <input matInput type="text" name="name" ngModel #name>
        <mat-hint align="end">Enter Name of the vendor</mat-hint>
      </mat-form-field><br>

      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput type="text" name="description" ngModel #description maxlength="50"></textarea>
        <mat-hint align="end">Enter the description of vendor</mat-hint>
      </mat-form-field><br>

      <mat-form-field appearance="outline">
        <mat-label>Balance</mat-label>
        <input matInput type="number" name="balance" ngModel #balance>
        <mat-hint align="end">Enter amount payable to vendor</mat-hint>
      </mat-form-field><br>
      <button mat-raised-button color="primary" type="submit">Create</button><br>
    </form>
  </ng-container>



  <!--//////////// Create Liability Modal /////////// -->

  <ng-container *ngIf="modalType === 'create-liability'">
    <form (submit)=createLiability(form) #form="ngForm" class="form">

      <mat-form-field>
        <mat-label>Select Vendor</mat-label>
        <mat-select name="vendor" ngModel required #vendor="ngModel">
          <mat-option *ngFor="let vendor of vendors" [value]="vendor._id">{{vendor.name}}</mat-option>
          <mat-error *ngIf="vendor.invalid">Please Select the vendor</mat-error>
        </mat-select>
      </mat-form-field>
      <br>

      <mat-form-field appearance="outline">
        <mat-label>Description</mat-label>
        <textarea matInput type="text" name="description" ngModel #description maxlength="50"></textarea>
        <mat-hint align="end">Enter the description of vendor</mat-hint>
      </mat-form-field>
      <br>


      <label>
        <h2>Select Purpose</h2>
      </label>
      <br>

      <mat-radio-group name="purpose" (change)="setLiabilityType($event)" ngModel required #purpose="ngModel">
        <mat-radio-button selected value="credit">Credit</mat-radio-button>
        <mat-radio-button value="debit">Debit</mat-radio-button>
        <mat-error *ngIf="purpose.invalid" style="font-size: 12px;">Please select a type</mat-error>
      </mat-radio-group>

      <br>

      <mat-form-field *ngIf="liabilityAccounts">
        <mat-label>Select Account</mat-label>
        <mat-select name="account" ngModel required #account="ngModel">
          <mat-option *ngFor="let account of accountName" [value]="account._id">{{account.paymentMethod}}</mat-option>
          <mat-error *ngIf="account.invalid">Please Select the account</mat-error>
        </mat-select>
      </mat-form-field>
      <br>

      <mat-form-field appearance="outline">
        <mat-label>Amount</mat-label>
        <input matInput type="number" name="amount" ngModel #amount>
        <mat-hint align="end">Enter amount for this transaction</mat-hint>
      </mat-form-field><br>
      <button mat-raised-button color="primary" type="submit">Create</button><br>
    </form>
  </ng-container>





</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Cancel</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Okay</button>
</mat-dialog-actions>
