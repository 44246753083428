import { Injectable, Inject, Component } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(public authService: AuthService, private snackBar: MatSnackBar, private matDialoge: MatDialog) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    if (authToken) {
      const authReq = req.clone({
        headers: req.headers.set('Authorization', 'Bearer ' + authToken)
      });
      return next.handle(authReq).pipe(
        tap((event: HttpEvent<any>) => {
          // && event.status === 201
          if (event instanceof HttpResponse ) {
            const snackBarRef = this.snackBar.open(event.body.message, 'Okay')._dismissAfter(3600);
          }
        }, err => {
          console.log(err);
          this.matDialoge.open(HttpDialogeComponent, {
            data: {
              errorType: err.error.error.status + "- " + err.status,
              message: err.error.message
            }
          });
        })
        // catchError(
        //   err =>
        //     new Observable<HttpEvent<any>>(observer => {
        //       if (err instanceof HttpErrorResponse) {
        //         console.log(err);
        //       }
        //       observer.error(err);
        //       observer.complete();
        //     })
        // )
      );
    } else {
      return next.handle(req);
    }
  }
}



@Component({
  selector: 'app-interceptor-dialog',
  template: `<h1 mat-dialog-title>{{data.errorType}}</h1>
  <div mat-dialog-content>
    <h4>{{data.message}}</h4>
  </div>
  <div mat-dialog-actions>
    <button mat-button mat-dialog-close>Ok</button>
  </div>`,
})
export class HttpDialogeComponent {

  constructor(
    public dialogRef: MatDialogRef<HttpDialogeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
