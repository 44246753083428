import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ArgonService } from 'src/app/services/argon.service';
import { orderElement } from '../order.model';
import { NgForm, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-order-modal',
  templateUrl: './order-modal.component.html',
  styleUrls: ['./order-modal.component.scss']
})
export class OrderModalComponent implements OnInit {
  type: any;
  editMode = false;
  oneProduct: any;
  buttonTitle = "Edit";

  title: String;
  content: any;
  cities: any;  // cities to show in html file
  singleOrder: any;
  trackingHistory: any;
  enableCities = false;
  isLoading = false;
  citiesLoading = false;
  singleOrderViewColumns = ['action', 'name', 'date'];

  count = 0;

  productColumns = Array();
  // [
  //   'name', 'price', 'qty', 'nameToPrint', 'nameOnOtherSide', 'giftWrap', 'refills', 'size', 'action'
  // ];

  orderColumns = ['sr_no','cn', 'orderId', 'name', 'phone', 'address', 'city', 'notes', 'status'];
  orderSource: any;
  productSource: any;

  constructor(
    private dialogRef: MatDialogRef<OrderModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private argonService: ArgonService,
    private snackBar: MatSnackBar,
    public matDialog: MatDialog) { }

  generateProductColumns(products: any) {
    let columns = Array();
    let keys;
    // columns = columns.toString().split(',').map(Number);

    products.forEach(element => {
      keys = Object.keys(element);
      // keys = keys.toString().split(',').map(Number);
      for (const i in keys) {
        if (!columns.includes(keys[i])) {
          if (keys[i] !== '_id') {
            columns.push(keys[i]);
          }
        }
      }
    });
    columns.push('action');
    return columns;
  }

  replaceOrder(order: orderElement) {
    // const index = this.orderSource._data._value.findIndex(existingOrder => existingOrder._id === order._id);


    const dataIndex = this.data.items.selected.findIndex(existingOrder => existingOrder._id === order._id);
    // const index2 = this.orderSource.filteredData.findIndex(existingOrder => existingOrder._id === order._id);
    // this.orderSource._data._value[index] = order;
    // this.orderSource.filteredData[index2] = order;

    // this.orderSource.data[index] = order;
    this.data.items.selected[dataIndex] = order;
    this.orderSource.data = this.data.items.selected;
    console.log({ orders: this.orderSource, message: 'i\'ve replaced the order' });
  }

  ngOnInit() {

    this.argonService.productListner().subscribe(value => {
      if (this.type !== 'editProduct' && this.type !== 'addProduct') {

        this.singleOrder.products = value.products;
        this.singleOrder.orderLog = value.orderLog;
        this.orderColumns = this.generateProductColumns(value.products);
      }
    });

    if (this.data.type === 'bookOrders') {
      this.type = 'bookOrders';
      this.title = 'Booking Orders Through ' + this.data.company;
      this.orderSource = new MatTableDataSource(this.data.items.selected);

      this.argonService.getSingleOrderListner().subscribe(response => {
        this.replaceOrder(response);  // Replace the current order in the order source
        // console.log()
        // this.orderSource.data = this.argonService.getOrders();  // This will refresh the Ui everytime the change has occured
      });
    } else if (this.data.type === 'changeCity') {
      this.type = 'changeCity';
      this.citiesLoading = true;

      if (this.data.company === 'trax') {
        // this.enableCities = true;
        if (!this.argonService.getCities()) {
          this.argonService.getTraxCities();
          this.argonService.getTraxCityListner().subscribe(event => {
            if (event === true) {
              this.cities = this.argonService.getCities();
              this.citiesLoading = false;
            }
          });
        } else {
          this.cities = this.argonService.getCities();
          this.citiesLoading = false;
        }
      } else if (this.data.company === 'leopard') {
        if (!this.argonService.getLeopardCities()) {
          this.argonService.fetchLeopardCities(); // fetch the leopard cities from leopard server
          this.argonService.getLeopardCityListner().subscribe(event => {
            if (event === true) {
              this.cities = this.argonService.getLeopardCities();
              this.citiesLoading = false;
            }
          });
        } else {
          this.cities = this.argonService.getLeopardCities();
          this.citiesLoading = false;
        }
      }
    } else if (this.data.type === 'viewOrder') {
      this.type = this.data.type;
      this.singleOrder = this.data.order;
      this.productSource = new MatTableDataSource(this.singleOrder.products)
      this.title = 'CN no: ' + this.singleOrder.cn;
      this.productColumns = this.generateProductColumns(this.singleOrder.products);


    } else if (this.data.type === 'createTrackingSheet') {
      this.type = 'createTrackingSheet';
      this.title = 'Create Receiving Sheet';
    } else if (this.data.type === 'editProduct') {
      this.type = this.data.type;
      this.title = 'Edit Product';
      this.oneProduct = this.data.product;
    } else if (this.data.type === 'addProduct') {
      this.type = this.data.type;
      this.title = 'Add Product';
    } else if (this.data.type === 'tracking') {
      this.type = this.data.type;
      this.isLoading = true;
      this.title = 'Tracking History';
      if (this.data.company === 'trax') {
        this.trackingHistory = this.argonService.traxTrackingHistory(this.data.trackingId).subscribe(history => {
          this.trackingHistory = history.data;
          this.isLoading = false;
        });
      } else if (this.data.company === 'leopard') {
        this.trackingHistory = this.argonService.leopardTrackingHistory(this.data.trackingId).subscribe(history => {
          this.trackingHistory = history.data;
          this.isLoading = false;
        });
      }

    }
  }


  hasProperty(o, name) {
    return o.hasOwnProperty(name);
  }


  enableCity(element) {

    const dialogRef = this.matDialog.open(OrderModalComponent, {
      data: {
        type: 'changeCity',
        item: element,
        company: this.data.company
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }


  trackOrder(trackingId: string, companyName: string) {

    const dialogRef = this.matDialog.open(OrderModalComponent, {
      data: {
        type: 'tracking',
        trackingId: trackingId,
        company: companyName
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  updateCity(city: orderElement) {
    console.log(city);
    const order: orderElement = this.data.item;
    this.argonService.updateOrderCity(city, order, this.data.company).subscribe(result => {
      // this.replaceOrder(result.data);
      this.argonService.replaceOrder(result.data);
      this.snackBar.open(result.message, 'Okay', {
        duration: 2000,
      });
    }, err => {
      this.snackBar.open(err.message, 'Okay', {
        duration: 2000,
      });
    });

  }



  enableTraxCities() {
    this.enableCities = !this.enableCities;
    // this.cities = this.traxCities;
  }


  changeMode() {
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.buttonTitle = 'Save';
    } else {
      this.buttonTitle = 'Edit';
    }
    console.log(this.editMode);

  }

  onSave(form: NgForm) {
    if (form.invalid) {
      return;
    }
    let historyDescription: string = '';
    console.log('submitted the form successfully');
    this.editMode = !this.editMode;
    if (this.editMode) {
      this.buttonTitle = 'Save';
    } else {
      this.buttonTitle = 'Edit';
      historyDescription = 'Changed the order details ::: Name From: ' +
        this.singleOrder.billing.first_name + ' ' + this.singleOrder.billing.last_name + ' to '
        + form.value.first_name + ' ' + form.value.last_name + ', City From: ' + this.singleOrder.billing.city + ' to ' + form.value.city
        + ', Amount From: ' + this.singleOrder.total + ' to ' + form.value.total
        + ', Remarks From:' + this.singleOrder.remarks + ' to ' + form.value.remarks;

      this.singleOrder.billing.first_name = form.value.first_name;
      this.singleOrder.billing.last_name = form.value.last_name;
      this.singleOrder.billing.city = form.value.city;
      this.singleOrder.billing.phone = form.value.phone;
      this.singleOrder.billing.address = form.value.address;
      this.singleOrder.billing.email = form.value.email;
      this.singleOrder.total = form.value.total;
      this.singleOrder.remarks = form.value.remarks;


      this.argonService.updateOrder(this.singleOrder, historyDescription).subscribe(result => {
        console.log(result);
      });
    }

  }



  openOrderDetailsDialog(selectedOrder: orderElement) {
    const dialogRef = this.matDialog.open(OrderModalComponent, {
      data: {
        type: 'viewOrder',
        order: selectedOrder
      }
    });
  }

  productAction(action: string, product: any) {
    if (action === 'Add') {
      const dialogRef = this.matDialog.open(OrderModalComponent, {
        data: {
          type: 'addProduct',
          orderId: this.singleOrder._id
        }
      });
    } else if (action === 'Update') {
      const dialogRef = this.matDialog.open(OrderModalComponent, {
        data: {
          type: 'editProduct',
          product,
          orderId: this.singleOrder._id
        }
      });
    } else if (action === 'Delete') {
      this.deleteProduct(product);
    }
  }

  async productSave(form: NgForm) {
    // form.value.push({productId: this.data.product._id});
    if (this.type === 'addProduct') {
      await this.argonService.addProduct(this.data.orderId, form.value);
    } else if (this.type === 'editProduct') {
      const values = { orderId: this.data.orderId, productId: this.oneProduct._id };
      await this.argonService.editProduct(values, form.value);
    }
    this.dialogRef.close();
  }

  deleteProduct(rowObj) {
    this.argonService.deleteProduct(this.singleOrder._id, rowObj._id);
  }


}
