import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InventoryService } from 'src/app/services/inventory.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-inventory-modal',
  templateUrl: './inventory-modal.component.html',
  styleUrls: ['./inventory-modal.component.css']
})
export class InventoryModalComponent implements OnInit {

  modalType = 'add-item';
  constructor(
    private dialogRef: MatDialogRef<InventoryModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public matDialog: MatDialog,
    private inventoryService: InventoryService,
    private router: Router) { }


  addItem(form: NgForm) {
    this.inventoryService.addItem(form.value).subscribe(result => {
      this.inventoryService.pushToInventoryListner(result.data);
      this.dialogRef.close();
    });
  }
  ngOnInit() {
    this.modalType = this.data.type;
  }

}
